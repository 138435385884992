import React from 'react'
import { View } from 'dripsy'

import AppLayout from '_components/AppLayout'
import Characters from '_components/Characters'
import LocationsBoxWrapper from '_components/LocationsBoxWrapper'

export const HomeScreen = () => {
  return (
    // <Text>aaa</Text>
    <AppLayout>
      <View sx={{ flexDirection: 'row' }}>
        <Characters />
        <LocationsBoxWrapper />
      </View>

      {/* Home Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque
      non quas totam excepturi id, commodi debitis suscipit, voluptatem
      necessitatibus recusandae atque incidunt cupiditate consequuntur nemo
      labore possimus et ab a. Lorem ipsum dolor sit amet consectetur
      adipisicing elit. Sed, reprehenderit odio! Aspernatur dolore natus alias
      veniam, ex ut tempora sint inventore, unde, esse adipisci nostrum
      obcaecati autem eos assumenda reprehenderit? */}
    </AppLayout>
  )
}
