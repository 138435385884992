import React from 'react'
import { View } from 'dripsy'

import AppLayout from '_components/AppLayout'
import LocationsBoxWrapper from '_components/LocationsBoxWrapper'

export const DetailsScreen = () => {
  return (
    <AppLayout>
      <View sx={{ flexDirection: 'row' }}>
        <LocationsBoxWrapper />
      </View>
    </AppLayout>
  )
}
