import React from 'react'

import { usePagination } from '_hooks/usePagination'
import { useAppState } from '_utils/store'
import LocationsBox from '_components/LocationsBox'

const LocationsBoxWrapper = (props) => {
  // const { location } = useGlobalState()
  // const { location } = useAppState()
  const { selectedValues } = useAppState()
  const location = usePagination('/location', selectedValues)

  return <LocationsBox location={location} />
}

export default LocationsBoxWrapper
