import React from 'react'

import { Button, Divider, Layout, TopNavigation } from '@ui-kitten/components'

import { useNavigation } from '@react-navigation/native'

import { Image } from 'react-native'

const Logo = () => {
  return (
    <Image
      style={{ width: 80, height: 53, margin: 10, marginRight: 'auto' }}
      source={{
        uri: 'https://www.across.sk/wp-content/uploads/2017/11/across-logo.svg',
      }}
    />
  )
}

export default Logo
