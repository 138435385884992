import React from 'react'

import { View, Text } from 'dripsy'
import { Spinner } from '@ui-kitten/components/ui'

const Loading = () => {
  return (
    <View sx={{ margin: 'auto' }}>
      <Spinner />
    </View>
  )
}

export default Loading
