import React from 'react'
import { BottomNavigation, BottomNavigationTab } from '@ui-kitten/components'
import { useNavigation, useNavigationState } from '@react-navigation/native'

const BottomNavigationSimpleUsageShowcase = ({ state }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const navigation = useNavigation()
  const navigationState = useNavigationState((state) => state)
  const index = navigationState.index
  // console.log(navigation);
  // console.log(index);

  return (
    <BottomNavigation
      selectedIndex={index}
      onSelect={(index) =>
        navigation.navigate(navigationState.routeNames[index])
      }
      // style={{ position: 'fixed', bottom: 0, wi dth: '100%'}}
    >
      <BottomNavigationTab title="HOME" />
      <BottomNavigationTab title="DETAILS" />
    </BottomNavigation>
  )
}

export default BottomNavigationSimpleUsageShowcase
