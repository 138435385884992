import React from 'react'
import { View } from 'dripsy'

const RoundedBox = (props) => {
  const { sx, ...rest } = props

  return (
    <View
      sx={{ borderRadius: 5, backgroundColor: 'white', height: '100%', ...sx }}
      {...rest}
    />
  )
}

export default RoundedBox
