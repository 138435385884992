import React from 'react'
import { Icon } from '@ui-kitten/components'
import { View, createThemedComponent } from 'dripsy'

import RoundedBox from '_UI/RoundedBox/RoundedBox'
import HeaderNav from './HeaderNav'

const HeaderNavBox = (props) => {
  return (
    <RoundedBox sx={{ p: 1 }}>
      <HeaderNav />
    </RoundedBox>
  )
}

export default createThemedComponent(HeaderNavBox)
