import React from 'react'
import { useMediaQuery } from 'react-responsive'

import HeaderMobile from './HeaderMobile'
import HeaderDesktop from './HeaderDesktop'

export default ({ navigation }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1000px)',
  })

  return isDesktopOrLaptop ? <HeaderDesktop /> : <HeaderMobile />
}
