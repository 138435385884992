import React from 'react'
import { StyleSheet, Platform } from 'react-native'
import { Button, Layout, Text } from '@ui-kitten/components'

import { useMediaQuery } from 'react-responsive'

import { View, ScrollView } from 'dripsy'

export default ({ children }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1000px)',
  })
  const isWeb = Platform.OS === 'web'

  // console.log(Platform)

  return (
    <>
      {/* Inner content component */}
      <Layout
        style={{
          flex: 1,
          // alignItems: 'center',
          backgroundColor: '#f1f1f1',
          margin: 8,
        }}
      >
        {/* <Button appearance="filled" onPress={navigateDetails}>
              OPEN DETAILS
            </Button> */}

        <View>{children}</View>
        {/* <Text>{children}</Text>
        <Text>{children}</Text>
        <Text>{children}</Text>
        <Text>{children}</Text> */}

        {/* <CircleButton /> */}

        {/* <View sx={{ flexDirection: "row", alignItems: "center" }}>
          <Text style={styles.text} category="h1">
            H1
          </Text>
          <Text style={styles.text} category="h2">
            H2
          </Text>
          <Text style={styles.text} category="h3">
            H3
          </Text>
          <Text style={styles.text} category="h4">
            H4
          </Text>
          <Text style={styles.text} category="h5">
            H5
          </Text>
          <Text style={styles.text} category="h6">
            H6
          </Text>
        </View> */}
      </Layout>
    </>
  )
}

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    margin: 2,
  },
})
