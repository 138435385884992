import React from 'react'
import { Button } from '@ui-kitten/components'
import { View } from 'dripsy'

import AppLayout from '_components/AppLayout'
import RoundedBox from '_UI/RoundedBox'
import Loading from '_UI/Loading'
import { Item } from '_components/Item'

import { useRequest } from '_hooks/useRequest'
import { usePagination } from '_hooks/usePagination'

const Characters = () => {
  const {
    results,
    size,
    setSize,
    isLoadingMore,
    isReachingEnd,
  } = usePagination('/character')

  console.log('renderujem rick box')
  console.log('rick', results)

  let hasPagination = false

  const finalResults = results ? (
    results.map((items, index) => {
      // console.log(items)
      hasPagination = !!items.info.next

      // `results` is an array of each page's API response.
      return items.results.map((item) => <Item key={item.id} itemData={item} />)
    })
  ) : (
    <Loading />
  )

  // if (error) showError = <h1>Something went wrong!</h1>
  // if (!result) loading = <Loading />

  return (
    <RoundedBox sx={{ p: 3, flexBasis: ['100%', '50%'], minHeight: 500, m: 2 }}>
      <Button disabled={!size} onPress={() => setSize(0)}>
        clear
      </Button>
      <Button disabled={!size} onPress={() => setSize(1)}>
        refresh
      </Button>
      <Button onPress={() => setSize(1)}>load</Button>

      <View>{finalResults}</View>

      {hasPagination ? (
        <Button
          disabled={isLoadingMore || isReachingEnd}
          onPress={() => {
            setSize(size + 1)
          }}
        >
          {isLoadingMore
            ? 'loading...'
            : isReachingEnd
            ? 'no more issues'
            : 'load more'}
        </Button>
      ) : null}
    </RoundedBox>
  )
}

export default Characters
