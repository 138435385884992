import * as eva from '@eva-design/eva'

export const myTheme = {
  ...eva,

  'color-primary-100': '#FEEAD5',
  'color-primary-200': '#FDCFAC',
  'color-primary-300': '#FAAE82',
  'color-primary-400': '#F68D63',
  'color-primary-500': '#F15A31',
  'color-primary-600': '#CF3B23',
  'color-primary-700': '#AD2218',
  'color-primary-800': '#8B0F10',
  'color-primary-900': '#730911',
  'color-primary-transparent-100': 'rgba(241, 90, 49, 0.08)',
  'color-primary-transparent-200': 'rgba(241, 90, 49, 0.16)',
  'color-primary-transparent-300': 'rgba(241, 90, 49, 0.24)',
  'color-primary-transparent-400': 'rgba(241, 90, 49, 0.32)',
  'color-primary-transparent-500': 'rgba(241, 90, 49, 0.4)',
  'color-primary-transparent-600': 'rgba(241, 90, 49, 0.48)',
  'color-success-100': '#EDFCD4',
  'color-success-200': '#D8F9AA',
  'color-success-300': '#B8EF7D',
  'color-success-400': '#98E05A',
  'color-success-500': '#6BCC2A',
  'color-success-600': '#50AF1E',
  'color-success-700': '#389215',
  'color-success-800': '#25760D',
  'color-success-900': '#176108',
  'color-success-transparent-100': 'rgba(107, 204, 42, 0.08)',
  'color-success-transparent-200': 'rgba(107, 204, 42, 0.16)',
  'color-success-transparent-300': 'rgba(107, 204, 42, 0.24)',
  'color-success-transparent-400': 'rgba(107, 204, 42, 0.32)',
  'color-success-transparent-500': 'rgba(107, 204, 42, 0.4)',
  'color-success-transparent-600': 'rgba(107, 204, 42, 0.48)',
  'color-info-100': '#CFF0FE',
  'color-info-200': '#9FDDFE',
  'color-info-300': '#6FC5FE',
  'color-info-400': '#4CADFD',
  'color-info-500': '#1187FC',
  'color-info-600': '#0C68D8',
  'color-info-700': '#084EB5',
  'color-info-800': '#053692',
  'color-info-900': '#032678',
  'color-info-transparent-100': 'rgba(17, 135, 252, 0.08)',
  'color-info-transparent-200': 'rgba(17, 135, 252, 0.16)',
  'color-info-transparent-300': 'rgba(17, 135, 252, 0.24)',
  'color-info-transparent-400': 'rgba(17, 135, 252, 0.32)',
  'color-info-transparent-500': 'rgba(17, 135, 252, 0.4)',
  'color-info-transparent-600': 'rgba(17, 135, 252, 0.48)',
  'color-warning-100': '#FFF9CC',
  'color-warning-200': '#FFF299',
  'color-warning-300': '#FFE866',
  'color-warning-400': '#FFDF3F',
  'color-warning-500': '#FFD000',
  'color-warning-600': '#DBAE00',
  'color-warning-700': '#B78E00',
  'color-warning-800': '#937000',
  'color-warning-900': '#7A5A00',
  'color-warning-transparent-100': 'rgba(255, 208, 0, 0.08)',
  'color-warning-transparent-200': 'rgba(255, 208, 0, 0.16)',
  'color-warning-transparent-300': 'rgba(255, 208, 0, 0.24)',
  'color-warning-transparent-400': 'rgba(255, 208, 0, 0.32)',
  'color-warning-transparent-500': 'rgba(255, 208, 0, 0.4)',
  'color-warning-transparent-600': 'rgba(255, 208, 0, 0.48)',
  'color-danger-100': '#FEE8D5',
  'color-danger-200': '#FECBAC',
  'color-danger-300': '#FDA883',
  'color-danger-400': '#FB8663',
  'color-danger-500': '#F94F31',
  'color-danger-600': '#D63023',
  'color-danger-700': '#B31819',
  'color-danger-800': '#900F1A',
  'color-danger-900': '#77091B',
  'color-danger-transparent-100': 'rgba(249, 79, 49, 0.08)',
  'color-danger-transparent-200': 'rgba(249, 79, 49, 0.16)',
  'color-danger-transparent-300': 'rgba(249, 79, 49, 0.24)',
  'color-danger-transparent-400': 'rgba(249, 79, 49, 0.32)',
  'color-danger-transparent-500': 'rgba(249, 79, 49, 0.4)',
  'color-danger-transparent-600': 'rgba(249, 79, 49, 0.48)',
}
