import React from 'react'

export function createStore(name = 'Store', reducer) {
  const StateContext = React.createContext({})
  const DispatchContext = React.createContext({})
  StateContext.displayName = `${name}State`
  DispatchContext.displayName = `${name}Dispatch`

  function Store({ defaultState, children }) {
    const [state, dispatch] = React.useReducer(reducer, defaultState)

    return (
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>
          {children}
        </DispatchContext.Provider>
      </StateContext.Provider>
    )
  }

  function useStoreState() {
    const context = React.useContext(StateContext)
    if (!context) {
      throw new Error(
        `useStoreState must be used within ${StateContext.displayName}.Provider`
      )
    }

    return context
  }

  function useStoreDispatch() {
    const context = React.useContext(DispatchContext)
    if (!context) {
      throw new Error(
        `useStoreDispatch must be used within ${DispatchContext.displayName}.Provider`
      )
    }

    return context
  }

  return [Store, useStoreState, useStoreDispatch]
}
