import React from 'react'

import { List, ListItem, Spinner } from '@ui-kitten/components'
import { View, Text } from 'dripsy'
import { Image } from 'react-native'

import { useRequest } from '_hooks/useRequest'

export const Item = ({ itemData }) => {
  const { id } = itemData
  const { data, error } = useRequest('/character', id)

  if (error) return <h1>Something went wrong!</h1>
  if (!data) return <Spinner />

  return (
    <View sx={{ flexDirection: 'row' }}>
      <Text sx={{ p: 2 }}>#{data.id}</Text>
      <Image
        style={{ width: 80, height: 80, margin: 10 }}
        source={{ uri: data.image }}
      />
      <Text sx={{ p: 2 }}>{data.name}</Text>
      {/* <Text sx={{ p: 2 }}>
        {data.types.map((poke) => poke.type.name).join(', ')}
      </Text> */}
    </View>
  )
}
