import React from 'react'
import { Text, Button, Select, SelectItem } from '@ui-kitten/components'
import { View, createThemedComponent } from 'dripsy'

import RoundedBox from '_UI/RoundedBox/RoundedBox'

import { useAppState, useAppDispatch } from '_utils/store'

const OverallStatsBox = (props) => {
  // const [selectedIndex, setSelectedIndex] = React.useState([
  //   new IndexPath(0),
  //   new IndexPath(1),
  // ])

  const { selectedIndex } = useAppState()
  const dispatch = useAppDispatch()

  const options = { earth: 'Earth', citadel: 'Citadel', mars: 'Mars' }

  // console.log(values)

  Object.keys({ earth: 'Earth', citadel: 'Citadel' }).filter

  console.log('renderujem overall stats')

  const renderOption = (title) => <SelectItem title={title} key={title} />
  // Multiselect
  // const displayValue = selectedIndex
  //   .map((index) => Object.keys(options)[index.row])
  //   .join(', ')

  // Singleselect
  const displayValue = Object.keys(options)[selectedIndex.row]

  return (
    <RoundedBox sx={{ p: 3 }}>
      <Text>Locations filter</Text>
      <Select
        // multiSelect={true}
        selectedIndex={selectedIndex}
        value={displayValue}
        onSelect={(index) => {
          console.log(index)

          // setSelectedIndex(index)

          // For multiselect
          // const values = index.map(
          //   (indexItem) => Object.keys(options)[indexItem.row]
          // )

          // For singleselect
          const values = [Object.keys(options)[index.row]]

          dispatch({
            type: 'SET_LOCATION_NAME_FILTER',
            selectedIndex: index,
            selectedValues: values,
          })
        }}
      >
        {Object.keys(options).map(renderOption)}
      </Select>
    </RoundedBox>
  )
}

export default createThemedComponent(OverallStatsBox)
