export function reducer(state, action) {
  switch (action.type) {
    case 'SET_LOCATION_NAME_FILTER':
      // console.log(object)
      // Add new filter to array
      // const locationNameFilter = [...state.locationNameFilter, action.payload]

      return {
        ...state,
        selectedIndex: action.selectedIndex,
        selectedValues: action.selectedValues,
      }
    default:
      throw new Error(`Unsupported action: ${action.type}`)
  }
}
