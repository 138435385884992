import React from 'react'
import { Text, Button } from '@ui-kitten/components'
import { View } from 'dripsy'

// import { useGlobalState } from '../context/GlobalStore'

import RoundedBox from '_UI/RoundedBox/RoundedBox'
import Loading from '_UI/Loading'

const LocationsBox = (props) => {
  const {
    results,
    size,
    setSize,
    isLoadingMore,
    isReachingEnd,
    isRefreshing,
    isEmpty,
    hasPagination,
    isError,
  } = props.location

  // console.log(selectedIndex)

  // console.log(displayValue)
  console.log(props.location)
  console.log('renderujem last transactions')

  // console.log(location)

  // let hasPagination = false

  const finalResults = results ? (
    results.map((items, index) => {
      // console.log(items)
      // hasPagination = !!items.info.next

      // `results` is an array of each page's API response.
      return items.results.map((item) => <Text key={item.id}>{item.name}</Text>)
    })
  ) : (
    <Text>No results found</Text>
  )

  return (
    <RoundedBox sx={{ p: 3, flexBasis: ['100%', '50%'], minHeight: 500, m: 2 }}>
      <Button disabled={!size} onPress={() => setSize(0)}>
        clear
      </Button>
      <Button disabled={!size} onPress={() => setSize(1)}>
        refresh
      </Button>
      <Button onPress={() => setSize(1)}>load</Button>

      <View>{finalResults}</View>
      <View>{isLoadingMore ? <Loading /> : null}</View>

      {hasPagination ? (
        <Button
          disabled={isLoadingMore || isReachingEnd}
          onPress={() => {
            setSize(size + 1)
          }}
        >
          {isLoadingMore
            ? 'loading...'
            : isReachingEnd
            ? 'no more issues'
            : 'load more'}
        </Button>
      ) : null}
    </RoundedBox>
  )
}

export default LocationsBox
