import { useSWRInfinite } from 'swr'

import { baseUrl } from '_hooks/useRequest'

const PAGE_SIZE = 5

const fetcher = async (url) => {
  const res = await fetch(url)
  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.')
    // Attach extra info to the error object.
    error.info = await res.json()
    error.status = res.status
    throw error
  }
  return await res.json()
}

export const usePagination = (path, filterValues = []) => {
  const getKey = (pageIndex, previousPageData) => {
    // reached the end
    if (previousPageData && !previousPageData.results) return null

    const url =
      baseUrl +
      path +
      `?page=${pageIndex + 1}` +
      // Apply name filtering
      (filterValues.length > 0 && filterValues !== undefined
        ? '&name=' + filterValues.join(',')
        : '')

    // console.log(url)

    return url
  }

  const { data, error, mutate, size, setSize, isValidating } = useSWRInfinite(
    getKey,
    fetcher,
    {
      persistSize: false,
      revalidateAll: true,
    }
  )

  console.log('validating', isValidating)

  // console.log(data)

  // Set different fetching states
  const results = data ? [].concat(...data) : []
  const isLoadingInitialData = !data && !error
  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 && data && typeof data[size - 1] === 'undefined')
  const isEmpty = data?.[0]?.length === 0
  const isReachingEnd = !!(
    isEmpty ||
    (data && data[data.length - 1]?.length < PAGE_SIZE)
  )
  const isRefreshing = !!(isValidating && data && data.length === size)
  const isError = !!error

  const hasPagination =
    results && results[results.length - 1] && results[results.length - 1].info
      ? !!results[results.length - 1].info.next
      : false

  return {
    results,
    size,
    setSize,
    isLoadingMore,
    isReachingEnd,
    isRefreshing,
    isEmpty,
    isError,
    hasPagination,
  }
}
