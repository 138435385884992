import React from 'react'

import { AppStore } from '_utils/store'

export function GlobalAppState(props) {
  return (
    <AppStore
      defaultState={{ selectedIndex: [], selectedValues: [] }}
      {...props}
    />
  )
}
