import useSwr from 'swr'

// API url
export const baseUrl = 'https://rickandmortyapi.com/api'

export const useRequest = (path: string, name?: string | number) => {
  if (!path) {
    throw new Error('Path is required')
  }

  const url = name ? baseUrl + path + '/' + name : baseUrl + path
  const { data, error } = useSwr(url)

  return { data, error }
}
