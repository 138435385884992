import React from 'react'

import { Button } from '@ui-kitten/components'
import { View, Text } from 'dripsy'
import { useNavigation } from '@react-navigation/native'

import Logo from '_components/Logo'

const SideNav = () => {
  const navigation = useNavigation()

  const navigateDetails = () => {
    navigation.navigate('Details')
  }
  const navigateHome = () => {
    navigation.navigate('Home')
  }

  return (
    <View
      sx={{
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 100,
        backgroundColor: '#ffffff',
      }}
    >
      <Logo />
      <Button onPress={navigateHome}>Home</Button>
      <Button onPress={navigateDetails}>Details</Button>
    </View>
  )
}

export default SideNav
