import React from 'react'
import { Icon, Layout, Text } from '@ui-kitten/components'
import { View } from 'dripsy'

import OverallStatsBox from '_components/OverallStatsBox'
import HeaderNavBox from './HeaderNavBox'

const BackIcon = (props) => <Icon {...props} name="arrow-back" />

const HeaderDesktop = ({ navigation }) => {
  const navigateDetails = () => {
    navigation.navigate('Details')
  }

  return (
    <Layout
      style={{
        paddingVertical: 10,
        paddingHorizontal: 5,
        backgroundColor: '#f1f1f1',
        flexDirection: 'row',
      }}
    >
      <View sx={{ flexGrow: 1, flexShrink: 1, flexBasis: 'auto', mx: 2 }}>
        <OverallStatsBox />
      </View>
      <View sx={{ flexGrow: 0, flexShrink: 0, flexBasis: 'auto', mx: 2 }}>
        <HeaderNavBox />
      </View>
    </Layout>
  )
}

export default HeaderDesktop
