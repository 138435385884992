import React from 'react'
import { Icon, Layout } from '@ui-kitten/components'
import { View } from 'dripsy'

import HeaderNav from './HeaderNav'

const HeaderMobile = ({ navigation }) => {
  return (
    <Layout>
      <HeaderNav />
    </Layout>
  )
}

export default HeaderMobile
