import React from 'react'
import { StatusBar } from 'expo-status-bar'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { SWRConfig } from 'swr'

// UI FW provider
import * as eva from '@eva-design/eva'
import { myTheme } from './src/theme/custom-theme'
import { default as mapping } from './src/theme/mapping.json'
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components'
import { EvaIconsPack } from '@ui-kitten/eva-icons'

import { ThemeProvider as DripsyThemeProvider } from 'dripsy'

import useCachedResources from './src/hooks/useCachedResources'
import { RootNavigator } from './src/navigation'

// import { GlobalStateProvider } from './src/context/GlobalStore'
import { GlobalAppState } from './src/context/GlobalAppState'

// Setup global SWR fetcher
const fetcher = async (url) => {
  const res = await fetch(url)
  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.')
    // Attach extra info to the error object.
    error.info = await res.json()
    error.status = res.status
    throw error
  }
  return await res.json()
}

export default function App() {
  const isLoadingComplete = useCachedResources()

  if (!isLoadingComplete) {
    return null
  } else {
    return (
      <SafeAreaProvider>
        {/* UI Kitten icons */}
        <IconRegistry icons={EvaIconsPack} />
        {/* Global SWR config */}
        <SWRConfig value={{ fetcher }}>
          {/* UI Kitten provider */}
          <ApplicationProvider
            {...eva}
            theme={{ ...eva.light, ...myTheme }}
            customMapping={mapping} // TODO: Remove this later and use metro.config.js
          >
            {/* Dripsy provider */}
            <DripsyThemeProvider
              theme={{
                // Not working with original Dripsy
                breakpoints: ['760px', '1440px'],
              }}
            >
              {/* Global App Context */}
              <GlobalAppState>
                <RootNavigator />
              </GlobalAppState>
            </DripsyThemeProvider>
          </ApplicationProvider>
        </SWRConfig>
        <StatusBar />
      </SafeAreaProvider>
    )
  }
}
