import React from 'react'
import { Platform } from 'react-native'
import { Text } from '@ui-kitten/components'

import { useMediaQuery } from 'react-responsive'

import { View, ScrollView } from 'dripsy'

import Header from '_components/Header'
import BottomTabs from '_components/BottomTabs'
import SideNav from '_components/SideNav'
import ContentArea from '_components/ContentArea'

export default (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1000px)',
  })
  const isWeb = Platform.OS === 'web'

  // console.log(isDesktopOrLaptop)

  // console.log(Platform)

  return (
    <View sx={{ flex: 1, flexDirection: 'row' }}>
      {isDesktopOrLaptop ? <SideNav /> : null}

      <View sx={{ flex: 1 }}>
        {/* Sticky mobile Header component */}
        {!isDesktopOrLaptop ? <Header /> : null}

        {/* This area should be scrollable */}
        <ScrollView
          sx={{ flex: 1 }}
          centerContent
          contentContainerStyle={{
            minHeight: isWeb && isDesktopOrLaptop ? '100%' : 'auto',
          }}
        >
          <View sx={{ flex: 1 }}>
            {/* Static Mobile Header component */}
            {isDesktopOrLaptop ? <Header /> : null}

            <ContentArea {...props} />

            {/* Footer component, show only for web? */}
            {isDesktopOrLaptop ? (
              <View sx={{}}>
                <Text style={{ padding: 10, backgroundColor: '#ffffff' }}>
                  Footer
                </Text>
              </View>
            ) : null}
          </View>
        </ScrollView>

        {/* Bottom tabs should be sticky */}
        {!isDesktopOrLaptop ? <BottomTabs /> : null}
      </View>
    </View>
  )
}
