import React from 'react'
import {
  Icon,
  Layout,
  Text,
  TopNavigation,
  TopNavigationAction,
  Button,
  MenuItem,
  OverflowMenu,
} from '@ui-kitten/components'
import { createThemedComponent } from 'dripsy'
import { useMediaQuery } from 'react-responsive'

import Logo from '_components/Logo'

const NotificationIcon = (props) => <Icon {...props} name="bell-outline" />
const LogoutIcon = (props) => <Icon {...props} name="power-outline" />
const EditIcon = (props) => <Icon {...props} name="edit" />
const MenuIcon = (props) => <Icon {...props} name="more-vertical" />

const HeaderNav = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1000px)',
  })

  const [menuVisible, setMenuVisible] = React.useState(false)

  const toggleMenu = () => {
    setMenuVisible(!menuVisible)
  }

  const renderMenuAction = () => (
    <TopNavigationAction icon={MenuIcon} onPress={toggleMenu} />
  )

  const renderRightActions = () => (
    <Layout
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      {!isDesktopOrLaptop ? <Logo /> : null}

      <Button
        accessoryLeft={NotificationIcon}
        appearance="ghost"
        style={{ paddingHorizontal: 5, marginHorizontal: 5 }}
      >
        {isDesktopOrLaptop ? <Text>Messages</Text> : null}
      </Button>

      <Button
        accessoryLeft={EditIcon}
        appearance="ghost"
        style={{ paddingHorizontal: 5, marginHorizontal: 5 }}
      >
        {isDesktopOrLaptop ? <Text>Settings</Text> : null}
      </Button>

      {isDesktopOrLaptop ? (
        <Button
          accessoryLeft={LogoutIcon}
          appearance="ghost"
          style={{ paddingHorizontal: 5, marginHorizontal: 5 }}
        >
          <Text>Logout</Text>
        </Button>
      ) : null}

      {!isDesktopOrLaptop ? (
        <OverflowMenu
          anchor={renderMenuAction}
          visible={menuVisible}
          onBackdropPress={toggleMenu}
        >
          <MenuItem accessoryLeft={LogoutIcon} title="Logout" />
        </OverflowMenu>
      ) : null}
    </Layout>
  )

  return <>{renderRightActions()}</>
}

export default createThemedComponent(HeaderNav)
